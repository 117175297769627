<template>
  <v-card>
    <v-toolbar color="grey darken-3" dark elevation="1">
      <v-toolbar-title>
        {{ `${title} ${isNew ? '(Створення)' : '(Редагування)'}` }}
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-menu offset-y v-model="menu">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
              class="grey darken-3"
              icon
              outlined
              :disabled="isNew"
              v-bind="attrs"
              v-on="on"
              :loading="getModalLoading"
              @click="menu = true"
          >
            <v-icon color="secondary">mdi-arrow-down-drop-circle-outline</v-icon>
          </v-btn>
        </template>
        <v-list dense rounded nav>
          <v-list-item link class="px-4" @click.stop="deleteDialog">
            <v-list-item-icon>
              <v-icon color="error lighten-1">mdi-delete-forever-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="font-weight-medium">Вилучити</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-toolbar>
    <v-card-text class="pt-4">
      <v-form v-model="formValid" ref="form">
        <v-row>
          <v-col cols="6" md="6">
            <v-text-field type="text" filled label="Код" v-model="code"
                          hide-details
                          disabled
                          color="grey"/>
          </v-col>
          <v-col cols="12" md="6">
            <v-select filled label="Вид нарахування" v-model="accrual_type"
                      hide-details required :rules="[v => !!v || 'Це поле є обов’язковим']"
                      :items="accrual_type_select"
                      :class="accrual_type ? '' : 'req-star'"
                      color="grey"
            />
          </v-col>
          <v-col cols="12" md="12">
            <v-text-field type="text" filled label="Назва коротка" v-model="short_name"
                          hide-details
                          required :rules="[v => !!v || 'Це поле є обов’язковим']"
                          :class="short_name ? '' : 'req-star'"
                          color="grey"/>
          </v-col>
          <v-col cols="12" md="12">
            <v-text-field type="text" filled label="Назва повна" v-model="full_name"
                          hide-details
                          required :rules="[v => !!v || 'Це поле є обов’язковим']"
                          :class="full_name ? '' : 'req-star'"
                          color="grey"/>
          </v-col>
          <v-col cols="12" md="6">
            <v-select filled label="Вид розрахунку" v-model="accrual_count_type"
                      hide-details required :rules="[v => !!v || 'Це поле є обов’язковим']"
                      :class="accrual_count_type ? '' : 'req-star'"
                      :items="accrual_count_type_select"
                      color="grey"
            />
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field type="text" filled label="Порядок розрахунку"
                          v-model="account_order"
                          hide-details
                          v-decimal
                          color="grey"/>
          </v-col>
          <v-col cols="12" md="6">
            <ACC_ChartAccount
                :value="chart_of_account"
                :class="{'req-star': !chart_of_account}"
                label="Рахунок бух.обліку"
                @autocompleteChange="chartOfAccountChange"
            />
          </v-col>
          <v-col cols="12" md="6">
            <v-select filled label="Група виплати" v-model="accrual_group"
                      hide-details required :rules="[v => !!v || 'Це поле є обов’язковим']"
                      :class="accrual_group ? '' : 'req-star'"
                      :items="accrual_group_select"
                      color="grey"
            />
          </v-col>
          <v-col cols="12" md="6">
            <v-select filled label="Група податків" v-model="tax_group"
                      hide-details required :rules="[v => !!v || 'Це поле є обов’язковим']"
                      :class="accrual_type ? '' : 'req-star'"
                      :items="accrual_tax_group_select"
                      color="grey"
            />
          </v-col>
          <v-col cols="12" md="6">
            <ACC_Timesheet_letter
                :value="timesheet_letter_id"
                label="Буква обліку роб.часу"
                @autocompleteChange="timeSheetLetterChange"
            />
          </v-col>
          <v-col cols="12" md="6">
            <ACC_Income_type_pix
                :value="income_type_pix_id"
                label="Код виду доходу ПДФО"
                @autocompleteChange="incomeTypePixChange"
            />
          </v-col>
          <v-col cols="12" md="6"></v-col>
          <v-col cols="12" md="6">
            <v-checkbox label="Це відпустка" v-model="is_vacation" color="secondary" hide-details/>
            <v-checkbox label="Це лікарняні" v-model="is_hospital" color="secondary" hide-details/>
          </v-col>
          <v-col cols="12" md="6">
            <v-checkbox label="Це нарах.за рах. ФСС" v-model="is_social_accrual" color="secondary" hide-details/>
            <v-checkbox label="Це нарах. військовим" v-model="is_military_accrual" color="secondary" hide-details/>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn depressed text :loading="getModalLoading"
             color="secondary darken-1" @click="crud_item" class="button-accept">
        <v-icon left>mdi-content-save</v-icon>
        Зберегти
      </v-btn>
      <v-btn depressed text tile color="grey" @click="closeModal">
        <v-icon left>mdi-close</v-icon>
        Відмінити
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import {
  CREATE_ACCRUAL_LIST_ELEMENTS,
  UPDATE_ACCRUAL_LIST_ELEMENTS,
  REMOVE_ACCRUAL_LIST_ELEMENTS
} from "@/store/actions/accounting/list";

import {ALERT_SHOW} from "@/store/actions/alert";
import {QUESTION_SHOW} from "@/store/actions/question";
import {accrual_type_select, accrual_tax_group_select, accrual_count_type_select, accrual_group_select} from "@/utils/icons";

import ModalComponentMixin from "@/mixins/modal_component";

const modalDeleteId = 'accrual_accounting_modal_delete'

export default {
  name: "HWP_Modal_Accounting_Accrual",
  mixins: [ModalComponentMixin],
  components: {
    ACC_ChartAccount: () => import("@/components/accounting/autocomplite/modal/ACC_Chart_of_account"),
    ACC_Timesheet_letter: () => import("@/components/accounting/autocomplite/modal/ACC_Timesheet_letter"),
    ACC_Income_type_pix: () => import("@/components/accounting/autocomplite/modal/ACC_Income_type_pix")
  },
  data() {
    return {
      accrual_type_select,
      accrual_tax_group_select,
      accrual_count_type_select,
      accrual_group_select,
      short_name: this.item.short_name,
      full_name: this.item.full_name,
      code: this.item.code || '',
      accrual_type: this.item.accrual_type || null,
      accrual_count_type: this.item.accrual_count_type || null,
      account_order: this.item.account_order || null,
      accrual_group: this.item.accrual_group || null,
      is_vacation: this.item.is_vacation,
      is_hospital: this.item.is_hospital,
      is_social_accrual: this.item.is_social_accrual,
      is_military_accrual: this.item.is_military_accrual,
      timesheet_letter_id: this.item.timesheet_letter_id || null,
      income_type_pix_id: this.item.income_type_pix_id || null,
      chart_of_account: this.item.chart_of_account || null,
      tax_group: this.item.tax_group,
    }
  },
  methods: {
    validateForm(only_bool = false) {
      const required_items = {
        'short_name': 'Скорочена назва',
        'full_name': 'Повна назва',
        'accrual_type': 'Вид нарахування',
        'accrual_count_type': 'Вид розрахунку',
        'accrual_group': 'Група для виплати',
        'chart_of_account': 'Рахунок обліку',
        'tax_group': 'Група податків',
      }

      let form_valid = true
      let errors = []

      Object.keys(required_items).forEach(key => {
        if (!this[key]) {
          form_valid = false
          if (!only_bool) {
            errors.push(required_items[key])
          }
        }
      })

      if (only_bool) return form_valid
      return {
        valid: form_valid,
        text: errors.join(', ')
      }

    },
    chartOfAccountChange(payload) {
      this.chart_of_account = (payload || {})?.value || null
    },
    timeSheetLetterChange(payload) {
      this.timesheet_letter_id = (payload || {})?.value || null
    },
    incomeTypePixChange(payload) {
      this.income_type_pix_id = (payload || {})?.value || null
    },
    closeModal() {
      this.$emit('closeModal')
      this.itemId = this.item.id
      this.short_name = this.item.short_name
      this.full_name = this.item.full_name
      this.code = this.item.code
      this.accrual_type = this.item.accrual_type || null
      this.accrual_count_type = this.item.accrual_count_type || null
      this.accrual_group = this.item.accrual_group || null
      this.account_order = this.item.account_order || null
      this.is_vacation = this.item.is_vacation
      this.is_hospital = this.item.is_hospital
      this.is_social_accrual = this.item.is_social_accrual
      this.is_military_accrual = this.item.is_military_accrual
      this.timesheet_letter_id = this.item.timesheet_letter_id
      this.income_type_pix_id = this.item.income_type_pix_id
      this.chart_of_account = this.item.chart_of_account
      this.tax_group = this.item.tax_group

      if (this.$refs.form) {
        this.$refs.form.resetValidation()
      }
    },
    deleteDialog() {
      this.menu = false
      const payload = {
        text: `Підтвердіть вилучення виду доходу ПДФО`,
        accept_button: true,
        id: modalDeleteId
      }
      this.$store.dispatch(QUESTION_SHOW, payload)
    },
    crud_item() {
      this.formValid = true

      this.$refs.form.validate()
      const form_valid = this.validateForm()
      if (!form_valid) {
        this.formValid = false
        this.$store.commit(ALERT_SHOW, {
          text: form_valid.text,
          color: 'error lighten-1'
        })
        return
      }

      const payload = {
        short_name: this.short_name,
        full_name: this.full_name,
        code: this.code,
        accrual_type: this.accrual_type || null,
        accrual_count_type: this.accrual_count_type || null,
        accrual_group: this.accrual_group || null,
        account_order: this.account_order || null,
        is_vacation: this.is_vacation,
        is_hospital: this.is_hospital,
        is_social_accrual: this.is_social_accrual,
        is_military_accrual: this.is_military_accrual,
        timesheet_letter_id: this.timesheet_letter_id,
        income_type_pix_id: this.income_type_pix_id,
        chart_of_account: this.chart_of_account,
        tax_group: this.tax_group
      }

      if (this.isNew) {
        this.$store.dispatch(CREATE_ACCRUAL_LIST_ELEMENTS, payload)
            .then(el => {
              if (el) {
                this.closeModal()
              }
            })
      } else {
        payload.id = this.itemId
        this.$store.dispatch(UPDATE_ACCRUAL_LIST_ELEMENTS, payload)
            .then(el => {
              if (el) {
                this.closeModal()
              }
            })
      }
    },
    watch_item() {
      this.watcher = this.$watch(
          'item',
          {
            deep: true,
            immediate: true,
            handler(payload) {
              this.itemId = payload.id
              this.short_name = payload.short_name
              this.full_name = payload.full_name
              this.code = payload.code
              this.account_order = payload.account_order || null
              this.accrual_type = payload.accrual_type || null
              this.accrual_count_type = payload.accrual_count_type || null
              this.accrual_group = payload.accrual_group || null
              this.is_vacation = payload.is_vacation
              this.is_hospital = payload.is_hospital
              this.is_social_accrual = payload.is_social_accrual
              this.is_military_accrual = payload.is_military_accrual
              this.timesheet_letter_id = payload.timesheet_letter_id
              this.income_type_pix_id = payload.income_type_pix_id
              this.chart_of_account = payload.chart_of_account
              this.tax_group = payload.tax_group
            }
          }
      )
    },
    watch_modal_answer() {
      this.delete_watcher = this.$watch(
          'modalAnswer',
          {
            handler(payload) {
              if (payload.id === modalDeleteId) {
                if (payload.answer) {
                  this.$store.dispatch(REMOVE_ACCRUAL_LIST_ELEMENTS, this.itemId)
                      .then(ok => {
                        if (ok) {
                          this.closeModal()
                        }
                      })
                }
              }
            }
          }
      )
    },
    watch_dialog() {
      this.dialog_watcher = this.$watch(
          'dialog',
          {
            immediate: true,
            handler(payload) {
              if (payload) {
                this.watch_item()
                this.watch_modal_answer()
              } else {
                if (this.watcher) {
                  this.watcher()
                }
                if (this.delete_watcher) {
                  this.delete_watcher()
                }
              }
            }
          }
      )
    }
  },
}
</script>
